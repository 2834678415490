enum ActionTypes {
    CLEAR_GLOBAL_STATE = 'shared/CLEAR_GLOBAL_STATE',
    SET_LANGUAGES = "shared/SET_LANGUAGES",
    GET_LANGUAGES = "shared/GET_LANGUAGES",
    SET_SERVER_CONNECTION_STATUS = "shared/SET_SERVER_CONNECTION_STATUS",
    SET_USER_LOGIN_STATUS = "shared/SET_USER_LOGIN_STATUS",
    SET_INIT_SERVER_CONNECTION = "shared/SET_INIT_SERVER_CONNECTION",
    SSE_SUBSCRIBE = "shared/SSE_SUBSCRIBE",
    GET_CURRENT_USER = "shared/GET_CURRENT_USER",
    SET_CURRENT_USER = "shared/SET_CURRENT_USER",
    SET_CURRENT_USER_GEOLOCATION = "shared/SET_CURRENT_USER_GEOLOCATION",
    UPDATE_CURRENT_USER = "shared/UPDATE_CURRENT_USER",
    UPDATE_CURRENT_USER_BY_ATTRIBUTE = "shared/UPDATE_CURRENT_USER_BY_ATTRIBUTE",
    GET_CURRENT_CUSTOMER = "shared/GET_CURRENT_CUSTOMER",
    UPDATE_CURRENT_CUSTOMER = "shared/UPDATE_CURRENT_CUSTOMER",
    SET_CURRENT_CUSTOMER = "shared/SET_CURRENT_CUSTOMER",
    GET_ACCESSIBLE_CUSTOMERS = "shared/GET_ACCESSIBLE_CUSTOMERS",
    SET_ACCESSIBLE_CUSTOMERS = "shared/SET_ACCESSIBLE_CUSTOMERS",
    UPDATE_CURRENT_CUSTOMER_BY_ATTRIBUTE = "shared/UPDATE_CURRENT_CUSTOMER_BY_ATTRIBUTE",
    GET_VEHICLE_LIST = "shared/GET_VEHICLE_LIST",
    SET_VEHICLE_LIST = "shared/SET_VEHICLE_LIST",
    UPDATE_VEHICLE = "shared/UPDATE_VEHICLE",
    UPDATE_VEHICLES = "shared/UPDATE_VEHICLES",
    SET_VEHICLE_LIST_PARAMS = "shared/SET_VEHICLE_LIST_PARAMS",
    SET_VEHICLE_ID = "shared/SET_VEHICLE_ID",
    SET_VEHICLES_TAILS = "shared/SET_VEHICLES_TAILS",
    SET_VEHICLE = "shared/SET_VEHICLE",
    SET_SWIPED_VEHICLE = "shared/SET_SWIPED_VEHICLE",
    GET_VEHICLE_DRIVERS = "shared/GET_VEHICLE_DRIVERS",
    SET_VEHICLE_DRIVERS = "shared/SET_VEHICLE_DRIVERS",
    GET_TAG_LIST = "shared/GET_TAG_LIST",
    SET_TAG_LIST = "shared/SET_TAG_LIST",
    ATTACH_TAG = "shared/ATTACH_TAG",
    DETACH_TAG = "shared/DETACH_TAG",
    REATTACH_TAG = "shared/REATTACH_TAG",
    SET_VEHICLE_TAG_PARAMS = "shared/SET_VEHICLE_TAG_PARAMS",
    SET_TAG = "shared/SET_TAG",
    CREATE_TAG = "shared/CREATE_TAG",
    UPDATE_TAG = "shared/UPDATE_TAG",
    SET_DELETE_TAG_ID = "shared/SET_DELETE_TAG_ID",
    DELETE_TAG = "shared/DELETE_TAG",
    SET_IS_OPEN_TAGS = "shared/SET_IS_OPEN_TAGS",
    SET_IS_OPEN_GROUPS = "shared/SET_IS_OPEN_GROUPS",
    SET_LOADING = "shared/SET_LOADING",
    SET_FREEZE = "shared/SET_FREEZE",
    SET_CENTERED = "shared/SET_CENTERED",
    USER_LOGOUT = "shared/USER_LOGOUT",
    SET_EXPAND = "shared/SET_EXPAND",
    GET_VEHICLE_ADDRESS = "shared/GET_VEHICLE_ADDRESS",
    SET_VEHICLE_ADDRESS = "shared/SET_VEHICLE_ADDRESS",
    SET_VEHICLE_CURRENT_POSITION = "shared/SET_VEHICLE_CURRENT_POSITION",
    SET_TRACK_UUID = "shared/SET_TRACK_UUID",
    SET_VEHICLES_TRACKS = "shared/SET_VEHICLES_TRACKS",
    SET_TRACK_PARAMS = "shared/SET_TRACK_PARAMS",
    GET_VEHICLE_TRACK = "shared/GET_VEHICLE_TRACK",
    SET_EMPTY_TRACK = "shared/SET_EMPTY_TRACK",
    SET_VISIBLE_STREAM = "shared/SET_VISIBLE_STREAM",
    SET_VISIBLE_MONITORING_INFO = "shared/SET_VISIBLE_MONITORING_INFO",
    SET_VEHICLE_ALARM_STATUS = "SHARED/SET_VEHICLE_ALARM_STATUS",
    SET_LAST_PING_TIMESTAMP = "shared/SET_LAST_PING_TIMESTAMP",
    SET_IMPORT_PROGRESS = "shared/SET_IMPORT_PROGRESS",
    GET_GEOFENCE_LIST = "shared/GET_GEOFENCE_LIST",
    SET_GEOFENCE_LIST = "shared/SET_GEOFENCE_LIST",
    UPDATE_GEOFENCE = "shared/UPDATE_GEOFENCE",
    SET_GEOFENCE_LIST_PARAMS = "shared/SET_GEOFENCE_LIST_PARAMS",
    SET_GEOFENCE_ID = "shared/SET_GEOFENCE_ID",
    SET_GEOFENCE = "shared/SET_GEOFENCE",
    UPDATE_GEOFENCE_BY_ATTRIBUTE = "shared/UPDATE_GEOFENCE_BY_ATTRIBUTE",
    SET_GEOFENCE_CRUD_MODE = "shared/SET_GEOFENCE_CRUD_MODE",
    CREATE_UPDATE_GEOFENCE = "shared/CREATE_UPDATE_GEOFENCE",
    START_DRAW_GEOFENCE = "shared/START_DRAW_GEOFENCE",
    SET_VISIBLE_GEOFENCE_INFO = "shared/SET_VISIBLE_GEOFENCE_INFO",
    ATTACH_DETACH_VEHICLES_TO_GEOFENCE = "shared/ATTACH_DETACH_VEHICLES_TO_GEOFENCE",
    SET_VEHICLE_GEOFENCE_LIST = "shared/SET_VEHICLE_GEOFENCE_LIST",
    UPDATE_VEHICLE_GEOFENCE = "shared/UPDATE_VEHICLE_GEOFENCE",
    UPDATE_VEHICLES_GEOFENCE = "shared/UPDATE_VEHICLES_GEOFENCE",
    GET_VEHICLE_GEOFENCE_LIST = "shared/GET_VEHICLE_GEOFENCE_LIST",
    DELETE_GEOFENCE = "shared/DELETE_GEOFENCE",
    START_EDIT_GEOFENCE_BY_ID = "shared/START_EDIT_GEOFENCE_BY_ID",
    END_EDIT_GEOFENCE = "shared/END_EDIT_GEOFENCE",
    SET_VEHICLE_LIST_ATTACHED_TO_GEOFENCE = "shared/SET_VEHICLE_LIST_ATTACHED_TO_GEOFENCE",
    GET_VEHICLE_LIST_ATTACHED_TO_GEOFENCE = "shared/GET_VEHICLE_LIST_ATTACHED_TO_GEOFENCE",
    SET_VEHICLE_AMOUNT_INSIDE_OUTSIDE_TO_GEOFENCE = "shared/SET_VEHICLE_AMOUNT_INSIDE_OUTSIDE_TO_GEOFENCE",
    GET_VEHICLE_AMOUNT_INSIDE_OUTSIDE_TO_GEOFENCE = "shared/GET_VEHICLE_AMOUNT_INSIDE_OUTSIDE_TO_GEOFENCE",
    SET_EDITED_GEOFENCE_CIRCLE_RADIUS = "shared/SET_EDITED_GEOFENCE_CIRCLE_RADIUS",
    GET_CHECKED_VEHICLES_POSITIONS = "shared/GET_CHECKED_VEHICLES_POSITIONS",
    SET_CHECKED_VEHICLES_POSITIONS = "shared/SET_CHECKED_VEHICLES_POSITIONS",
    SET_CHECKED_VEHICLES_POSITIONS_PARAMS = "shared/SET_CHECKED_VEHICLES_POSITIONS_PARAMS",
    SET_LOADING_VEHICLES_POSITIONS = "shared/SET_LOADING_VEHICLES_POSITIONS",
    SET_VEHICLE_SENSORS = "shared/SET_VEHICLE_SENSORS",
    SET_VEHICLE_SENSORS_BY_ID = "shared/SET_VEHICLE_SENSORS_BY_ID",
    GET_VEHICLE_SENSORS = "shared/GET_VEHICLE_SENSORS",
    GET_VEHICLE_SENSORS_BY_ID = "shared/GET_VEHICLE_SENSORS_BY_ID",
    SET_VISIBLE_TRACKING_INFO = "shared/SET_VISIBLE_TRACKING_INFO",
    SET_TRACK_SEGMENT_UUID = "shared/SET_TRACK_SEGMENT_UUID",
    SET_TRACK_SEGMENT_EVENT = "shared/SET_TRACK_SEGMENT_EVENT",
    SET_FOLLOW_VEHICLE_ID = "shared/SET_FOLLOW_VEHICLE_ID",
    SET_VISIBLE_GEOZONES_LAYER = "shared/SET_VISIBLE_GEOZONES_LAYER",
    SET_VISIBLE_VEHICLE_LIST = "shared/SET_VISIBLE_VEHICLE_LIST",
    SET_VISIBLE_MOBILE_MENU_BTN = "shared/SET_VISIBLE_MOBILE_MENU_BTN",
    SET_CRUD_MODE = "shared/SET_CRUD_MODE",
    GET_POSITION_BY_ID = "shared/GET_POSITION_BY_ID",
    GET_POSITION_BY_TIMESTAMP = "shared/GET_POSITION_BY_TIMESTAMP",
    SET_POSITION = "shared/SET_POSITION",
    SET_RAW_TRACK_FORCE = "shared/SET_RAW_TRACK_FORCE",
    SET_RAW_TRACK_POSITION = "shared/SET_RAW_TRACK_POSITION",
    SET_GEOFENCE_EDITABLE_LAYER = "shared/SET_GEOFENCE_EDITABLE_LAYER",
    IMPORT_VEHICLES_POSITIONS = "shared/IMPORT_VEHICLES_POSITIONS",
    LOADING_FILE = "shared/LOADING_FILE",
    LOADING_FILE_NAME = "shared/LOADING_FILE_NAME",
    OPEN_IMPORT_LIST = "shared/OPEN_IMPORT_LIST",
    SET_IMPORT_LIST_IN_PROGRESS = "shared/SET_IMPORT_LIST_IN_PROGRESS",
    SET_IMPORT_HISTORY = "shared/SET_IMPORT_HISTORY",
    GET_IMPORT_HISTORY = "shared/GET_IMPORT_HISTORY",
    SET_DRAW_MODE = "shared/SET_DRAW_MODE",
    SAVE_IMAGE = "shared/SAVE_IMAGE",
    SET_SCROLL_POSITION = "shared/SET_SCROLL_POSITION",
    EXPORT_GEOFENCES = "shared/EXPORT_GEOFENCES",
    IMPORT_GEOFENCES = "shared/IMPORT_GEOFENCES",
    SET_PENDING_REFRESH_TOKEN = "shared/SET_PENDING_REFRESH_TOKEN",
    LOGIN_TO_CLIENT = "shared/LOGIN_TO_CLIENT",
    SET_LAST_SELECTED_VEHICLE_ID = "shared/SET_LAST_SELECTED_VEHICLE_ID",
    SET_LAST_SELECTED_VEHICLE_STATE = "shared/SET_LAST_SELECTED_VEHICLE_STATE",
    SET_IS_EDIT_TRACK = "shared/SET_IS_EDIT_TRACK",
    UPDATE_TRACK_POSITION = "shared/UPDATE_TRACK_POSITION",
    ADD_TRACK_POSITION = "shared/ADD_TRACK_POSITION",
    ACTIVATE_TRACK_POSITIONS = "shared/ACTIVATE_TRACK_POSITIONS",
    DEACTIVATE_TRACK_POSITIONS = "shared/DEACTIVATE_TRACK_POSITIONS",
    RESTORE_TRACK_POSITIONS = "shared/RESTORE_TRACK_POSITIONS",
    SELECT_TRACK_POSITION_INDEX = "shared/SELECT_TRACK_POSITION_INDEX"
}

export default ActionTypes;